// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { useStyles } from './Styles';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom'
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { collapse_sider } from "./Actions";
import { expand } from "../Header/Actions"
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import { getUserData, getUserId, getUserRole } from '../../../Services/LocalStorageService';
import { isAuthorized } from '../../../Routes/Guards/AuthGuard/AuthGuard';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

const drawerWidth = 280;

interface menuItem {
    title: '',
    url: '',
    icon: ''
}

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function Sider() {
    // Set Theme
    const theme = useTheme();

    // Css for module
    const classes = useStyles();

    // Router object
    const navigate = useNavigate();

    // current location
    const location = useLocation();

    // Redux
    const sider = useSelector(
        (state: RootStateOrAny) => state.sider,
    );

    const header = useSelector(
        (state: RootStateOrAny) => state.header,
    );

    // Dispatcher
    const dispatch = useDispatch()

    // UI vars
    const [open, setOpen] = React.useState(sider.state.isExpanded);
    const [visible, setVisibile] = React.useState(false);

    // Hooks
    useEffect(() => {
        setOpen(sider.state.isExpanded);
    }, [sider]);

    useEffect(() => {
        // setOpen(!header.state.isExpanded)
    }, [header]);

    useEffect(() => {
        const authorizedMenuItems = menuItems.filter(item => isAuthorized(getUserRole(), `/${item.url}`));
        setMenuItems(authorizedMenuItems)

        isAuthorized(getUserRole(), 'sider', 'components') ? setVisibile(true) : setVisibile(false)
    }, [])

    // UI toggles
    const handleDrawerClose = () => {
        dispatch(collapse_sider()); //sider state collapses
        // dispatch(expand()); //header state expands
        // setOpen(false);
    };

    const navigateToComponent = (url) => {
        navigate(url)
    }

    const isPageSelected = (item) => {
        return location.pathname == ('/' + item.url)
    }


    // Menu Items
    const [menuItems, setMenuItems] = useState([
        {
            title: 'Roles',
            url: 'role',
            icon: 'user-lock',
            subHeading: 'Admin',

        },
        {
            title: 'Users',
            url: 'user',
            icon: 'users'
        },
        {
            title: 'Import',
            url: 'import',
            icon: 'file-import'
        },
        {
            title: 'Email Template',
            url: 'emailtemplate',
            icon: 'envelope'
        },
        {
            title: 'Organizations',
            url: 'organization',
            icon: 'sitemap'
        },
        {
            title: 'Schools',
            url: 'school',
            icon: 'school'
        },
        {
            title: 'Employer Focus & Types',
            url: 'employerfocus',
            icon: 'users-viewfinder',
            subHeading: 'Configurations'
        },
        {
            title: 'Job Families',
            url: 'configurations/jobfamily',
            icon: 'user-tie'
        },
        {
            title: 'Contracted Roles',
            url: 'contractedrole',
            icon: 'user-graduate'
        },
        {
            title: 'Scope Of Roles',
            url: 'scopeofrole',
            icon: 'users-gear'
        },
        {
            title: 'Roll Counts',
            url: 'rollcount',
            icon: 'stopwatch-20'
        },
        {
            title: 'Curriculum',
            url: 'curriculum',
            icon: 'list-check'
        },
        {
            title: 'Department & Subjects',
            url: 'subject',
            icon: 'book',
        },
        {
            title: 'Additional Responsibilities',
            url: 'additionalresponsibility',
            icon: 'user-plus'
        },
        {
            title: 'Qualifications',
            url: 'qualificationconfig',
            icon: 'graduation-cap'
        },
        {
            title: 'Qualification Awards',
            url: 'qualificationaward',
            icon: 'graduation-cap'
        },
        {
            title: 'Head Categories',
            url: 'headcategory',
            icon: 'people-roof'
        },
        {
            title: 'Title',
            url: 'title',
            icon: 'person'
        },
        {
            title: 'Referee Verification',
            url: 'refereeVerification',
            icon: 'user-check'
        },
        {
            title: 'Countries',
            url: 'country',
            icon: 'flag'
        }
        // %TEMPLATE_MENU_ITEMS%


    ]);

    const handleMouseEnter = () => {
        setOpen(true);
    };

    const handleMouseLeave = () => {
        setOpen(false);
    };

    return (
        <>
            {visible && <Drawer
                variant="permanent"
                open={open}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {menuItems.map((item: any, index: any) =>
                        <div className="mx-1">
                            {item.subHeading &&
                                <div className="px-4 py-2 bg-light" style={{ borderRadius: '10px' }}>
                                    {open && <Typography variant="subtitle2">{item.subHeading}</Typography>}
                                </div>
                            }
                            <Tooltip title={item.title} placement="right">
                                <ListItemButton
                                    className={isPageSelected(item) ? 'bg-custom-primary-faded' : ''}
                                    onClick={() => navigateToComponent(`/${item.url}`)}
                                    key={item.title}
                                    sx={{
                                        py: 0,
                                        minHeight: 48,
                                        borderRadius: '15px !important',
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                    disableRipple
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 2 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FontAwesomeIcon width='1em' icon={item.icon} color={isPageSelected(item) ? 'black' : 'inherit'} />

                                    </ListItemIcon>

                                    <ListItemText sx={{ fontSize: '0.5em', opacity: open ? 1 : 0 }} >
                                        <Typography variant="overline" style={{ fontWeight: isPageSelected(item) ? 'bold' : '' }} >{item.title}</Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </Tooltip>
                        </div>
                    )}
                </List>
            </Drawer>}

        </>
    );
}

export default Sider;
