//@ts-nocheck
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getUserData, isLoggedIn, removeJWT, getUserRole } from '../../../Services/LocalStorageService';
import Unauthorized from '../../../Components/Screens/Authentication/UnauthorizedPage/UnauthorizedPage';

const user = {
    admin: process.env.REACT_APP_ADMIN_ROLE_ID,
    governer: process.env.REACT_APP_GOVERNER_ROLE_ID,
    hr: process.env.REACT_APP_HR_ROLE_ID,
    groupLeadership: process.env.REACT_APP_GROUP_LEADERSHIP_ROLE_ID,
    approvalAuthority: process.env.REACT_APP_APPROVAL_AUTHORITY_ROLE_ID,
    hiringManager: process.env.REACT_APP_HIRING_MANAGER_ROLE_ID,
    hiringSupport: process.env.REACT_APP_HIRING_SUPPORT_ROLE_ID,
    dsl: process.env.REACT_APP_DSL_ROLE_ID,
    inspector: process.env.REACT_APP_INSPECTOR_ROLE_ID,
    employee: process.env.REACT_APP_EMPLOYEE_ROLE_ID,
    candidate: process.env.REACT_APP_CANDIDATE_ROLE_ID,
}

const allUsersRoles = [user.admin, user.governer, user.hr, user.groupLeadership, user.approvalAuthority, user.hiringManager, user.hiringSupport, user.dsl,
user.inspector, user.employee, user.candidate]

const allOrganizationRoles = [user.governer, user.hr, user.groupLeadership, user.approvalAuthority, user.hiringManager, user.hiringSupport, user.dsl, user.inspector]

const accessGroups: any = {
    routes: {
        /** Roles Specific to everyone */
        '/logout': allUsersRoles,
        '/login': allUsersRoles,
        '/dashboard': allUsersRoles,
        '/myProfile': allUsersRoles,
        '/vacancy': allUsersRoles,
        '/import': [user.admin],

        /** Admin  */
        '/profile': allUsersRoles,
        '/user': [user.admin],
        '/role': [user.admin],
        '/emailtemplate': [user.admin],
        '/organization': [user.admin, user.client],
        '/school': [user.admin, ...allOrganizationRoles],
        '/onboard': [user.admin, user.candidate],
        '/onboard/education': [user.admin, user.candidate],
        '/onboard/career-history': [user.admin, user.candidate],
        '/onboard/career-summary': [user.admin, user.candidate],
        '/onboard/identity-verification': [user.admin, user.candidate],
        '/onboard/police-check': [user.admin, user.candidate],
        '/onboard/your-details': [user.admin, user.candidate],
        '/subject': [user.admin],
        '/headcategory': [user.admin],
        '/curriculum': [user.admin],
        '/qualificationconfig': [user.admin],
        '/title': [user.admin],
        '/rollcount': [user.admin],
        '/namechangereason': [user.admin],
        '/refereeVerification': [allUsersRoles],
        '/additionalresponsibility': [user.admin],
        '/scopeofrole': [user.admin],
        '/configurations': [user.admin],
        '/configurations/jobfamily': [user.admin],
        '/employerfocus': [user.admin],
        '/qualificationaward': [user.admin],
        '/contractedrole': [user.admin],
        '/country': [user.admin]
    },
    components: {
        'sider': [user.admin],
    },
    features: {
        userDetailsEdit: [user.admin],
        headerAdminControl: [user.admin, ...allOrganizationRoles],
        addEditVacancy: [user.admin, user.hr, user.hiringManager],
        candidateFullProfile: [user.admin, user.hr, user.approvalAuthority],
        candidateRestrictedProfile: [user.hiringManager, user.hiringSupport],
        messageCandidate: [user.admin, user.hr, user.approvalAuthority, user.hiringManager, user.hiringSupport],
        changeCandidateStatus: [user.admin, user.hr, user.approvalAuthority, user.hiringManager, user.hiringSupport],
        inviteCandidate: [user.admin, user.hr, user.approvalAuthority, user.hiringManager, user.hiringSupport],
        assignCandidateToOtherVacancy: [user.admin, user.hr, user.approvalAuthority],
        assignRole: [user.admin, user.hr],
    }
}

export const isLoggedInUserCandidate = () => {
    return (getUserRole() == user.candidate)
}

export const isLoggedInUserClient = () => {
    const role = getUserRole();
    return allOrganizationRoles.includes(role.toString())
}

export const isAuthorized = (roleId: any, path: any, module = 'routes') => {
    try {
        try {
            if (accessGroups[module][path].includes(roleId.toString())) return true;
            return false
        } catch (error) {
            console.log(`Unauthorized Access: ${path}`)
            return false
        }
    } catch (error) {
        return false
    }
}

export const getOrganizationalRoles = () => {
    return allOrganizationRoles;
}

export const AuthGuard: React.FC<AuthGuardProps> = ({ path, children, fallback: FallbackComponent = null }) => {

    const location = useLocation();
    const auth = isLoggedIn(); // determine if authorized, from context or however you're doing it

    if (!auth) {
        removeJWT();

        // Check if we're on the login path
        const isLoginPath = (location.pathname === '/login') || (location.pathname === '/');

        if (isLoginPath) {
            // If not authorized and we're on the login path, redirect to login without a redirect parameter
            return <Navigate to="/login" replace />;
        }

        // If not authorized and we're not on the login path, redirect to login with the current path as the redirect
        const loginPath = `/login?redirect=${encodeURIComponent(location.pathname + location.search)}`;
        return <Navigate to={loginPath} replace />;
    }

    const useRoleId = getUserData().roleId

    // Redirect from '/' to '/dashboard'
    if (path === '/' && auth) {
        return <Navigate to="/dashboard" />;
    }


    if (useRoleId && isAuthorized(useRoleId, path)) {
        return <>{children}</>
        // return <RequestedComponent />;
    } else {
        return FallbackComponent ? <FallbackComponent /> : <Navigate to="/unauthorized" />;
    }
}
