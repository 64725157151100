// @ts-nocheck
import React, { useState, useRef, useEffect } from "react";
import {
    Button,
    Card,
    CardContent,
    TextField,
    Box,
    FormControl,
    Slide,
    Snackbar,
    Alert,
    Tooltip,
    IconButton,
    InputAdornment,
    Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { post } from "../../../../Services/GenericApiService";
import AlertM from "../../../Helpers/AlertM/AlertM";
import LoginImageSrc from "../../../../Assets/images/login-img.png";
import LoginImageSrc2 from "../../../../Assets/images/login-img2.png";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { encrypt } from "../../../../Services/CryptoService";
import {
    getUserData,
    storeJWT,
    storeUserOrganizations,
} from "../../../../Services/LocalStorageService";
import { login } from "./Actions";
import { LoadingButton } from "@mui/lab";
import { getOrganizationalRoles } from "../../../../Routes/Guards/AuthGuard/AuthGuard";
import { getAllByCustomParams } from "../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import { setActiveOrganizations } from "../../../../Global/Actions";

function Login() {
    // translation
    const { t } = useTranslation();

    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
    } = useForm();
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const notifications = useRef();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirectPath = queryParams.get("redirect");

    // state in redux
    const authentication = useSelector(
        (state: RootStateOrAny) => state.authentication
    );

    // Dispatcher
    const dispatch = useDispatch();

    const onEmailSubmit = (data) => {
        console.log("on email triggered");
        if (showPasswordField) {
            // If we are showing the password field, attempt to log the user in
            onLogin(data);
        } else {
            // Check if the user is registered
            checkEmailRegistered(data.email);
        }
    };

    const checkEmailRegistered = async (email) => {
        setLoading(true);
        try {
            const response = await post("user/auth/checkEmail", { email });
            setLoading(false);
            if (response.registered) {
                setShowPasswordField(true); // Show password field if user is registered
                setValue("email", email); // Keep the email in the form
            } else {
                // Navigate to the Register component with the email prefilled
                navigate("/register", { state: { email } });
            }
        } catch (error) {
            setLoading(false);
            notifications.current.errorAlert("Error", "Failed to check email");
        }
    };

    const onLogin = (_data) => {
        setLoading(true); // show loader
        // encrypt data
        _data.password = encrypt(_data.password);

        post("user/auth/email", _data)
            .then((_res) => {
                // Stores JWT
                storeJWT(_res.token);
                // Stores Account Info in Global State and dispatches it
                let userData = getUserData();
                // Dispatches in Global State Store
                dispatch(login(true, _res.token, userData));
                loginRedirectMechanism(userData);
            })
            .catch((_err) => {
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(
                    t("login.loginFailed"),
                    t("login.invalidCredentials")
                );
            });
    };

    const loginRedirectMechanism = async () => {
        try {
            await checkAndFetchUersOrganizations();
        } catch (_err) {
            console.log(_err);
        }

        if (redirectPath) {
            navigate(decodeURIComponent(redirectPath));
        } else {
            navigate("/dashboard");
        }
    };

    const checkAndFetchUersOrganizations = () => {
        const currentUser = getUserData();
        if (
            currentUser?.accountType === "organization" ||
            currentUser?.accountType === "admin" ||
            currentUser?.accountType === "employee"
        ) {
            const params = "organizations=true";
            getAllByCustomParams("userorganizationschool/list/user", params)
                .then((_res) => {
                    const organizations = _res;

                    if (organizations && Array.isArray(organizations)) {
                        storeUserOrganizations(_res);

                        if (organizations.length > 0) {
                            const activeOrg = _res[0];
                            // storeActiveOrganization(activeOrg)
                            // Dispatches in Global State Store
                            dispatch(setActiveOrganizations(activeOrg));
                        }
                    }
                    setLoading(false); // hide loader
                })
                .catch((_err) => {
                    setLoading(false); // hide loader
                    // @ts-ignore
                    notifications?.current?.errorAlert(
                        `Something's wrong`,
                        `Could not fetch Organizations`
                    );
                });
        }
        setLoading(false); // hide loader
        return;
    };

    const resetPassword = (email) => { 
        post("user/auth/resetPasswordRequest", {email})
            .then((_res) => {
                notifications.current.successAlert(
                    "Reset Password Mail Sent!",
                    "Click the link in mail to reset password"
                );
            })
            .catch((_err) => {
                notifications.current.errorAlert(
                    "Reset Password Mail Not sent",
                    _err.message
                );
            });
    }

    return (
        <section className="h-100 d-flex justify-content-center align-items-center bg-light">
            <Box sx={{ maxWidth: 400, width: "100%" }}>
                <form onSubmit={handleSubmit(onEmailSubmit)} autocomplete="on">
                    {/* <Slide direction="up" in={true} mountOnEnter unmountOnExit> */}
                    <Card
                        variant="outlined"
                        sx={{
                            p: 3,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <CardContent
                            sx={{
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            {/* {redirectPath?.includes("/vacancy/") && (
                                <Box
                                    component="img"
                                    src={LoginImageSrc2}
                                    sx={{ width: "80%", mb: 4 }}
                                />
                            )} */}
                            <Box
                                component="img"
                                src={LoginImageSrc2}
                                sx={{ width: "80%", mb: 4 }}
                            />

                            <FormControl fullWidth margin="normal">
                                            <TextField
                                    {...register("email", {
                                        required: "Email is required",
                                                })}
                                    label="Email"
                                    type="email"
                                    placeholder="john.doe@email.com"
                                    error={!!errors.email}
                                    helperText={errors.email?.message}
                                    disabled={loading || showPasswordField}
                                    autocomplete="username"
                                />
                                </FormControl>
                            <Slide
                                in={showPasswordField}
                                direction="left"
                                mountOnEnter
                                unmountOnExit
                            >
                                <FormControl
                                    fullWidth
                                    margin="normal"
                                    style={{
                                        display: showPasswordField
                                            ? "block"
                                            : "none",
                                    }}
                                >
                                    {showPasswordField && (
                                        <div>
                                            <TextField
                                                {...register("password", {
                                                    required:
                                                        "Password is required",
                                                })}
                                                label="Password"
                                                type={
                                                    showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                placeholder="Your password here"
                                                error={!!errors.password}
                                                helperText={
                                                    errors.password?.message
                                                }
                                                fullWidth
                                                autoFocus
                                                autocomplete="current-password"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() =>
                                                                    setShowPassword(
                                                                        !showPassword
                                                                    )
                                                                }
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityOffIcon />
                                                                ) : (
                                                                    <RemoveRedEyeIcon />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    )}
                                </FormControl>
                            </Slide>

                            <LoadingButton
                                loading={loading}
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="full-width my-2"
                            >
                                {showPasswordField ? "Login" : "Next"}
                            </LoadingButton>
                            <Typography
                                sx={{
                                    display: showPasswordField
                                        ? "block"
                                        : "none",
                                }}
                            >
                                Forgot Password?{" "}
                                <span
                                        onClick={()=>resetPassword(watch("email"))}
                                        style={{
                                            cursor: "pointer",
                                            color: "#9c27b0",
                                            fontWeight: "bold",
                                        }}
                                    >Reset</span>
                            </Typography>
                        </CardContent>
                    </Card>
                    {/* </Slide> */}
                </form>
            </Box>

            <AlertM ref={notifications} />
        </section>
    );
}

export default Login;
