// @ts-nocheck
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

// Import components
import Login from "../Components/Screens/Authentication/Login/Login";
import Register from "../Components/Screens/Authentication/Register/Register";
import Home from "../Components/Screens/Dashboard/Home/Home"
import Logout from "../Components/Screens/Authentication/Logout/Logout";
import { RootStateOrAny, useSelector } from "react-redux";
import { useTranslation, Trans } from 'react-i18next';
import { AuthGuard } from "./Guards/AuthGuard/AuthGuard";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { getById } from '../Services/GenericApiService';
import { useDispatch } from 'react-redux';
import OrganizationFetcher from './OrganizationFetcher/OrganizationFetcher';
import Reset from '../Components/Screens/Authentication/Reset/Reset';

// Lazy load components
// const Landing = lazy(() => import("../Components/Screens/Landing/Landing"));
// const Login = lazy(() => import("../Components/Screens/Authentication/Login/Login"));
// const Register = lazy(() => import("../Components/Screens/Authentication/Register/Register"));
// const Home = lazy(() => import("../Components/Screens/Dashboard/Home/Home"));
// const Logout = lazy(() => import("../Components/Screens/Authentication/Logout/Logout"));
const RoleMain = lazy(() => import("../Components/Screens/Dashboard/Role/RoleMain/RoleMain"));
const UserMain = lazy(() => import("../Components/Screens/Dashboard/User/UserMain/UserMain"));
const SubjectMain = lazy(() => import("../Components/Screens/Dashboard/Subject/SubjectMain/SubjectMain"));
const HeadcategoryMain = lazy(() => import("../Components/Screens/Dashboard/Headcategory/HeadcategoryMain/HeadcategoryMain"));
const HeadsubcategoryMain = lazy(() => import("../Components/Screens/Dashboard/Headsubcategory/HeadsubcategoryMain/HeadsubcategoryMain"));
const CurriculumMain = lazy(() => import("../Components/Screens/Dashboard/Curriculum/CurriculumMain/CurriculumMain"));
const CurriculumphaseMain = lazy(() => import("../Components/Screens/Dashboard/Curriculumphase/CurriculumphaseMain/CurriculumphaseMain"));
const QualificationawardMain = lazy(() => import("../Components/Screens/Dashboard/Qualificationaward/QualificationawardMain/QualificationawardMain"));
const QualificationinstituteMain = lazy(() => import("../Components/Screens/Dashboard/Qualificationinstitute/QualificationinstituteMain/QualificationinstituteMain"));
const QualificationlevelMain = lazy(() => import("../Components/Screens/Dashboard/Qualificationlevel/QualificationlevelMain/QualificationlevelMain"));
const Qualificationconfigurationmain = lazy(() => import("../Components/Screens/Dashboard/Qualificationconfiguration/Qualificationconfigurationmain"));
const TitleMain = lazy(() => import("../Components/Screens/Dashboard/Title/TitleMain/TitleMain"));
const EmailVerification = lazy(() => import("../Components/Screens/Authentication/EmailVerification/EmailVerification"));
const MyProfileMain = lazy(() => import("../Components/Screens/Dashboard/Candidate/MyProfile/MyProfileMain/MyProfileMain"));
const RollcountMain = lazy(() => import("../Components/Screens/Dashboard/Rollcount/RollcountMain/RollcountMain"));
const NamechangereasonMain = lazy(() => import("../Components/Screens/Dashboard/Namechangereason/NamechangereasonMain/NamechangereasonMain"));
const AdditionalresponsibilityMain = lazy(() => import("../Components/Screens/Dashboard/Additionalresponsibility/AdditionalresponsibilityMain/AdditionalresponsibilityMain"));
const ScopeofroleMain = lazy(() => import("../Components/Screens/Dashboard/Scopeofrole/ScopeofroleMain/ScopeofroleMain"));
const JobfamilyMain = lazy(() => import("../Components/Screens/Dashboard/Jobfamily/JobfamilyMain/JobfamilyMain"));
const EmployerfocusMain = lazy(() => import("../Components/Screens/Dashboard/Employerfocus/EmployerfocusMain/EmployerfocusMain"));
const EmployertypeMain = lazy(() => import("../Components/Screens/Dashboard/Employertype/EmployertypeMain/EmployertypeMain"));
const ContractedroleMain = lazy(() => import("../Components/Screens/Dashboard/Contractedrole/ContractedroleMain/ContractedroleMain"));
const CountryMain = lazy(() => import("../Components/Screens/Dashboard/Country/CountryMain/CountryMain"));
const EmailtemplatesMain = lazy(() => import("../Components/Screens/Dashboard/Emailtemplates/EmailtemplatesMain/EmailtemplatesMain"));
const RefereeVerificationMain = lazy(() => import("../Components/Screens/Dashboard/RefereeVerification/RefereeVerificationMain/RefereeVerificationMain"));
const OrganizationMain = lazy(() => import("../Components/Screens/Dashboard/Organization/OrganizationMain/OrganizationMain"));
const MyProfileMaintenanceMain = lazy(() => import("../Components/Screens/Dashboard/MyProfileMaintenance/MyProfileMaintenanceMain/MyProfileMaintenanceMain"));
const SchoolDashbordMain = lazy(() => import("../Components/Screens/Dashboard/SchoolDashbord/SchoolDashbordMain/SchoolDashbordMain"));
const OrganizationDashboard = lazy(() => import("../Components/Screens/Dashboard/Organization/OrganizationDashboard/OrganizationDashboard"));
const CandidateProfileMain = lazy(() => import("../Components/Screens/Dashboard/CandidateProfileReview/CandidateProfileMain/CandidateProfileMain"));
const VacancyViewMain = lazy(() => import("../Components/Screens/Dashboard/Vacancy/VacancyView/VacancyView"));
const PageNotFound = lazy(() => import("../Components/Screens/Dashboard/PageNotFound/PageNotFound"));
const Unauthorized = lazy(() => import("../Components/Screens/Authentication/UnauthorizedPage/UnauthorizedPage"));
const VacancyView = lazy(() => import("../Components/Screens/Dashboard/Vacancy/VacancyView/VacancyView"));
const SchoolMain = lazy(() => import("../Components/Screens/Dashboard/Organization/SchoolMain/SchoolMain"));
const Importer = lazy(() => import("../Components/Screens/Dashboard/Importer/Importer"));

// %TEMPLATE_IMPORT_COMPONENT%

function Router() {

    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();

    // get current theme
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );


    const darkTheme = createTheme({
        palette: {
            mode: theme,
            primary: {
                main: "#000000",
                contrastText: "#fff" //button text white instead of black to support dark theme
            },

        },
        components: {
            MuiButton: {
                defaultProps: {
                    disableRipple: true, // Disable the ripple effect for all buttons
                },
            },
            MuiIconButton: {
                defaultProps: {
                    disableRipple: true, // Disable the ripple effect for all buttons
                },
            },
            MuiSelect: {
                defaultProps: {
                    inputProps: {
                        shrink: true,
                    },
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        minWidth: 120,
                    },
                },
            },
            MuiTextField: {
                defaultProps: {
                    // InputProps: { disableUnderline: true },
                    // InputLabelProps: {
                    //     shrink: true,
                    // },
                },
                styleOverrides: {
                    root: {
                        // overflow: 'hidden',
                        borderRadius: 4,
                        transition: 'border-color 0.1s, background-color 0.3s, box-shadow 0.3s',
                        '&:hover': {
                        },
                        '&.Mui-focused': {
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        // color: '#fff', // White for inactive tabs
                        '&.Mui-selected': {
                            color: '#000000', // Gold-like for active tabs
                        },
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        // backgroundColor: '#000000', // Gold-like for the indicator
                    },
                },
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        border: `1px solid rgba(0, 0, 0, .125)`,
                        '&:not(:last-child)': {
                            borderBottom: 0,
                        },
                        '&::before': {
                            display: 'none',
                        },
                        borderRadius: '8px',
                    },
                },
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgba(0, 0, 0, 0.9) !important',
                        color: '#fff',
                        flexDirection: 'row-reverse',
                        borderRadius: '8px',
                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                            transform: 'rotate(-90deg)', // Rotate the icon to face right when expanded
                        },
                    },
                    expandIconWrapper: {
                        '& svg': {
                            // Target the SVG icon directly
                            color: 'white', // Set the icon color to white
                        },
                    },
                },
                defaultProps: {
                    // Note: This approach for the icon doesn't directly apply styles to the icon via the theme.
                    // You will still need to manually set the icon color when defining the expandIcon prop.
                },
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: {
                        padding: '16px',
                        backgroundColor: 'rgba(0, 0, 0, .01)',
                        borderTop: '1px solid rgba(0, 0, 0, .125)',
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <BrowserRouter>

            <OrganizationFetcher /> 
            
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        {/* Protected Routes */}

                        <Route path="/" element={<AuthGuard path='/'><Home /></AuthGuard>} />
                        <Route path="login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/user/auth/reset/*" element={<Reset />} />
                        <Route path="/user/auth/reset/*/*" element={<Reset />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/verifyEmail" element={<EmailVerification />} />
                        <Route path="/dashboard" element={<AuthGuard path='/dashboard'><Home /></AuthGuard>} />
                        <Route path="/import" element={<AuthGuard path='/import'><Importer /></AuthGuard>} />
                        <Route path="/role" element={<AuthGuard path='/role'><RoleMain /></AuthGuard>} />
                        <Route path="/organization" element={<AuthGuard path='/organization'><OrganizationMain /></AuthGuard>} />
                        <Route path="/school" element={<AuthGuard path='/school'><SchoolMain /></AuthGuard>} />
                        <Route path="/user" element={<AuthGuard path='/user'><UserMain /></AuthGuard>} />
                        <Route path="/emailtemplate" element={<AuthGuard path='/emailtemplate'><EmailtemplatesMain /></AuthGuard>} />
                        <Route path="/subject" element={<AuthGuard path='/subject'><SubjectMain /></AuthGuard>} />
                        <Route path="/headcategory" element={<AuthGuard path='/headcategory'><HeadsubcategoryMain /></AuthGuard>} />
                        <Route path="/curriculum" element={<AuthGuard path='/curriculum'><CurriculumMain /></AuthGuard>} />
                        <Route path="/curriculumphase" element={<AuthGuard path='/curriculumphase'><CurriculumphaseMain /></AuthGuard>} />
                        <Route path="/qualificationconfig" element={<AuthGuard path='/qualificationconfig'><Qualificationconfigurationmain /></AuthGuard>} />
                        <Route path="/headsubcategory" element={<AuthGuard path='/headsubcategory'><HeadsubcategoryMain /></AuthGuard>} />
                        <Route path="/qualificationaward" element={<AuthGuard path='/qualificationaward'><QualificationawardMain /></AuthGuard>} />
                        <Route path="/profile/:profileId" element={<AuthGuard path='/profile'><CandidateProfileMain /></AuthGuard>} />
                        <Route path="/myProfile" element={<AuthGuard path='/myProfile'><MyProfileMaintenanceMain /></AuthGuard>} />
                        <Route path="/school/:schoolId/:section" element={<AuthGuard path='/school'><SchoolDashbordMain /></AuthGuard>} />
                        <Route path="/school/:schoolId" element={<AuthGuard path='/school'><SchoolDashbordMain /></AuthGuard>} />
                        <Route path="/configurations/jobfamily" element={<AuthGuard path='/configurations/jobfamily'><JobfamilyMain /></AuthGuard>} />
                        <Route path="/refereeVerification" element={<RefereeVerificationMain />} />
                        <Route path="/qualificationinstitute" element={<AuthGuard path='/qualificationinstitute'><QualificationinstituteMain /></AuthGuard>} />
                        <Route path="/qualificationlevel" element={<AuthGuard path='/qualificationlevel'><QualificationlevelMain /></AuthGuard>} />
                        <Route path="/title" element={<AuthGuard path='/title'><TitleMain /></AuthGuard>} />
                        <Route path="/onboard/*" element={<AuthGuard path='/onboard'><MyProfileMain /></AuthGuard>} />
                        {/* <Route path="/vacancy" element={<AuthGuard path='/vacancy'><VacancyView /></AuthGuard>} /> */}
                        <Route path="/rollcount" element={<AuthGuard path='/rollcount'><RollcountMain /></AuthGuard>} />
                        <Route path="/namechangereason" element={<AuthGuard path='/namechangereason'><NamechangereasonMain /></AuthGuard>} />
                        <Route path="/additionalresponsibility" element={<AuthGuard path='/additionalresponsibility'><AdditionalresponsibilityMain /></AuthGuard>} />
                        <Route path="/scopeofrole" element={<AuthGuard path='/scopeofrole'><ScopeofroleMain /></AuthGuard>} />
                        <Route path="/employerfocus" element={<AuthGuard path='/employerfocus'><EmployerfocusMain /></AuthGuard>} />
                        <Route path="/employertype" element={<AuthGuard path='/employertype'><EmployertypeMain /></AuthGuard>} />
                        <Route path="/contractedrole" element={<AuthGuard path='/contractedrole'><ContractedroleMain /></AuthGuard>} />
                        <Route path="/country" element={<AuthGuard path='/country'><CountryMain /></AuthGuard>} />


                        {/* Public Routes */}

                        <Route path="/vacancy/:vacancyId" element={<VacancyView isModalView={false} buttonText='Apply to this vacancy' />} />
                        <Route path="*" element={<PageNotFound />} />

                    </Routes>

                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default Router;
