//@ts-nocheck
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import zxcvbn from "zxcvbn";
import {
    Button,
    Card,
    CardContent,
    TextField,
    Snackbar,
    Alert,
    AlertTitle,
    FormControl,
    IconButton,
    InputAdornment,
    Typography,
    Slide,
    Box,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getAll, post } from "../../../../Services/GenericApiService";
import { encrypt } from "../../../../Services/CryptoService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const brandImageSrc = require("../../../../Assets/images/login-img.png");
const brandImageSrc2 = require("../../../../Assets/images/login-img2.png");

const personalEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "icloud.com",
    "mail.com",
    "zoho.com",
    "protonmail.com",
    "yandex.com",
    "gmx.com",
];

const schema = yup
    .object({
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        email: yup
            .string()
            .email("Enter a valid email")
            .required("Email is required")
            .test(
                "is-personal-email",
                "Only personal email addresses are allowed",
                (value) => {
                    if (!value) return false;
                    const domain = value.split("@")[1];
                    return personalEmailDomains.includes(domain);
                }
            ),
        countryCode: yup.string().required("Code is required"),
        contact: yup.string().required("Mobile Number is required"),
        password: yup
            .string()
            .min(8, "Password must be at least 8 characters long")
            .test(
                "password-validation",
                "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character",
                (value) => {
                    if (!value) return true; // Pass validation if value is empty or undefined
                    const hasUpperCase = /[A-Z]/.test(value);
                    const hasLowerCase = /[a-z]/.test(value);
                    const hasNumber = /[0-9]/.test(value);
                    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
                    return (
                        hasUpperCase &&
                        hasLowerCase &&
                        hasNumber &&
                        hasSpecialChar
                    );
                }
            )
            .test(
                "no-personal-info",
                "Password must not contain personal information",
                function (value) {
                    if (!value) return true; // Pass validation if value is empty or undefined
                    const { firstName, lastName, email } = this.parent;
                    const lowerValue = value.toLowerCase();
                    const lowerFirstName = firstName.toLowerCase();
                    const lowerLastName = lastName.toLowerCase();
                    const lowerEmail = email.toLowerCase();
                    return (
                        !lowerValue.includes(lowerFirstName) &&
                        !lowerValue.includes(lowerLastName) &&
                        !lowerValue.includes(lowerEmail)
                    );
                }
            )
            .required("Password is required"),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Confirm Password is required"),
    })
    .required();

function Register() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [showPassword, setShowPassword] = React.useState(false);
    const [passwordStrength, setPasswordStrength] = useState({
        score: 0,
        feedback: "",
    });
    const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false);
    const [openErrorAlert, setOpenErrorAlert] = React.useState(false);
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [countryCodes, setCountryCodes] = React.useState([]);
    const countryCodesApi = "country/countries";

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirectPath = queryParams.get("redirect");

    // Call this function every time the password field is updated
    const checkPasswordStrength = (password) => {
        const result = zxcvbn(password);
        setPasswordStrength({
            score: result.score,
            feedback:
                result.feedback.warning || result.feedback.suggestions[0] || "",
        });
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();

    const handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    const onSubmit = (data) => {
        setLoading(true);
        data.password = encrypt(data.password);
        post("user", data)
            .then((res) => {
                setLoading(false);
                setOpenSuccessAlert(true);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
                setOpenErrorAlert(true);
                setError(err.message);
            });
    };

    const fetchCountries = () => {
        setLoading(true); // show loader
        getAll(countryCodesApi)
            .then((_res: any) => {
                setCountryCodes(_res);
                setLoading(false); // hide loader
            })
            .catch((_err) => {
                console.error(_err);
                setLoading(false); // hide loader
            });
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    return (
        <section className="h-90 d-flex justify-content-center align-items-center bg-light">
            <Box sx={{ maxWidth: 500, width: "100%" }}>
                <div className="py-3 h-100 d-flex align-items-center">
                    <Slide
                        direction="left"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                    >
                        <Card
                            variant="outlined"
                            sx={{ p: 3, width: "100%", overflow: "hidden" }}
                        >
                            <CardContent>
                                <div className="col-md-12 mx-md-2 mx-0">
                                    <div className="col-md-8 mx-auto">
                                        <img
                                            className="img-fluid mx-auto my-auto"
                                            src={brandImageSrc2}
                                            alt=""
                                        />
                                    </div>
                                    <div className="mt-5">
                                        <p>{t("register.tagline")}</p>
                                    </div>
                                </div>

                                <form
                                    className="row px-2"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <FormControl fullWidth className="my-2">
                                        <TextField
                                            {...register("firstName")}
                                            label={t("form.firstName")}
                                            error={!!errors.firstName}
                                            helperText={
                                                errors.firstName?.message
                                            }
                                        />
                                    </FormControl>

                                    <FormControl fullWidth className="my-2">
                                        <TextField
                                            {...register("lastName")}
                                            label={t("form.lastName")}
                                            error={!!errors.lastName}
                                            helperText={
                                                errors.lastName?.message
                                            }
                                        />
                                    </FormControl>

                                    <FormControl fullWidth className="my-2">
                                        <TextField
                                            {...register("email")}
                                            label={t("form.email")}
                                            type="email"
                                            error={!!errors.email}
                                            helperText={errors.email?.message}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth className="my-2">
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <FormControl
                                                sx={{ mr: 1, minWidth: 120 }}
                                            >
                                                <InputLabel id="country-code-label">
                                                    {t("form.countryCode")}
                                                </InputLabel>
                                                <Select
                                                    labelId="country-code-label"
                                                    label={t(
                                                        "form.countryCode"
                                                    )}
                                                    {...register("countryCode")}
                                                    error={!!errors.countryCode}
                                                >
                                                    {countryCodes.map(
                                                        (element) => (
                                                            <MenuItem
                                                                value={
                                                                    element.id
                                                                }
                                                                key={element.id}
                                                            >
                                                                <img
                                                                    src={
                                                                        element.flag
                                                                    }
                                                                    alt={`Flag of ${element.name}`}
                                                                    style={{
                                                                        width: "20px",
                                                                        marginRight:
                                                                            "8px",
                                                                    }}
                                                                />
                                                                +
                                                                {
                                                                    element.countryCode
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                {...register("contact")}
                                                label={t("form.contact")}
                                                type="number"
                                                error={!!errors.contact}
                                                helperText={
                                                    errors.contact?.message
                                                }
                                                fullWidth
                                            />
                                        </Box>
                                    </FormControl>

                                    <FormControl fullWidth className="my-2">
                                        <Typography
                                            variant="body2"
                                            sx={{ mt: 1, mb: 2 }}
                                        >
                                            Password should be at least 8
                                            characters long, include at least
                                            one uppercase letter, one lowercase
                                            letter, one number and one special
                                            character. It must not contain any
                                            personal information too.
                                        </Typography>
                                        <TextField
                                            {...register("password")}
                                            label={t("form.password")}
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            error={
                                                !!errors.password ||
                                                passwordStrength.score < 2
                                            }
                                            helperText={
                                                errors.password?.message
                                            }
                                            onChange={(e) =>
                                                checkPasswordStrength(
                                                    e.target.value
                                                )
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={
                                                                handleClickShowPassword
                                                            }
                                                            onMouseDown={
                                                                handleMouseDownPassword
                                                            }
                                                        >
                                                            {showPassword ? (
                                                                <FontAwesomeIcon
                                                                    icon={faEye}
                                                                />
                                                            ) : (
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEyeSlash
                                                                    }
                                                                />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: "",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {passwordStrength.feedback}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                Password Strength:&nbsp;
                                                {(passwordStrength.score ===
                                                    0 ||
                                                    passwordStrength.score ===
                                                        1 ||
                                                    errors.password) &&
                                                    "Weak"}
                                                {passwordStrength.score === 2 &&
                                                    !errors.password &&
                                                    "Fair"}
                                                {passwordStrength.score === 3 &&
                                                    !errors.password &&
                                                    "Good"}
                                                {passwordStrength.score === 4 &&
                                                    !errors.password &&
                                                    "Strong"}
                                            </Typography>
                                        </Box>
                                    </FormControl>

                                    <FormControl fullWidth className="my-2">
                                        <TextField
                                            {...register("confirmPassword")}
                                            label={t("form.confirmPassword")}
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            error={!!errors.confirmPassword}
                                            helperText={
                                                errors.confirmPassword?.message
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={
                                                                handleClickShowPassword
                                                            }
                                                            onMouseDown={
                                                                handleMouseDownPassword
                                                            }
                                                        >
                                                            {showPassword ? (
                                                                <FontAwesomeIcon
                                                                    icon={faEye}
                                                                />
                                                            ) : (
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEyeSlash
                                                                    }
                                                                />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>

                                    <LoadingButton
                                        loading={loading}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="full-width my-2"
                                    >
                                        {t("module.register")}
                                    </LoadingButton>
                                </form>

                                <Typography
                                    variant="body2"
                                    className="text-center my-2"
                                >
                                    {t("register.haveAccount")}{" "}
                                    <Button
                                        color="secondary"
                                        onClick={() => navigate("/login")}
                                    >
                                        {t("module.login")}
                                    </Button>
                                </Typography>

                                <Typography
                                    variant="body2"
                                    className="text-center my-2"
                                >
                                    Click{" "}
                                    <span
                                        onClick={() => navigate("/login")}
                                        style={{
                                            cursor: "pointer",
                                            color: "#9c27b0",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        HERE
                                    </span>{" "}
                                    for Support
                                </Typography>
                            </CardContent>
                        </Card>
                    </Slide>
                </div>

                <Snackbar
                    open={openSuccessAlert}
                    autoHideDuration={6000}
                    onClose={handleCloseAlert}
                >
                    <Alert
                        onClose={handleCloseAlert}
                        severity="success"
                        sx={{ width: "100%" }}
                    >
                        <AlertTitle>{t("register.createSuccess")}</AlertTitle>
                        {t("register.creationMailSent")}
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={openErrorAlert}
                    autoHideDuration={6000}
                    onClose={handleCloseAlert}
                >
                    <Alert
                        onClose={handleCloseAlert}
                        severity="error"
                        sx={{ width: "100%" }}
                    >
                        <AlertTitle>
                            {t("common.somethingWentWrong")}
                        </AlertTitle>
                        {error}
                    </Alert>
                </Snackbar>
            </Box>
        </section>
    );
}

export default Register;
