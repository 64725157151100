// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Typography, Card, CardActionArea, Grid } from '@mui/material';
import { getUserData, storeActiveOrganization, retrieveActiveOrganization, retrieveUserOrganizations } from '../../../../Services/LocalStorageService';
import { getAllByCustomParams } from '../../../../Services/GenericApiService';
import { setActiveOrganizations } from '../../../../Global/Actions';

const ClientOrganizationList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [userOrganizations, setUserOrganizations] = useState([]);

    useEffect(() => {
        fetchUserOrganizations();
    }, []);

    const fetchUserOrganizations = () => {

        const userOrganizations = retrieveUserOrganizations();
        if (!userOrganizations || userOrganizations.length == 0) {
            const params = 'organizations=true';
            getAllByCustomParams('userorganizationschool/list/user', params)
                .then(organizations => {
                    setUserOrganizations(organizations);
                    setLoading(false);
                })
                .catch(_err => {
                    console.error("Could not fetch Organizations");
                    setLoading(false);
                });
        } else {
            setUserOrganizations(userOrganizations);
            setLoading(false)
        }
    };

    const handleOrganizationSelect = (organization) => {
        storeActiveOrganization(organization);
        dispatch(setActiveOrganizations(organization));
        navigateToOrgURl(organization.url);
    };

    const navigateToOrgURl = (url: any) => {
        // Get the protocol (http or https)
        const protocol = window.location.protocol;

        // Get the base domain from the environment variable
        const baseDomain = process.env.REACT_APP_CLIENT_HOST;

        // Construct the new URL based on the selected organization's URL
        const newPath = window.location.pathname;
        const newUrl = `${protocol}//${url}.${baseDomain}${newPath}`;

        // Redirect the browser to the new URL
        window.location.href = newUrl;
    }

    return (
        <>
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                    <CircularProgress />
                </Box>
            )}

            {!loading && (
                <Box sx={{ flexGrow: 1, p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Select Your Organization
                    </Typography>
                    <Typography paragraph sx={{ mb: 2 }}>
                        Choose an organization to proceed to your organization's dashboard.
                    </Typography>
                    <Grid container spacing={2} sx={{ mt: 3 }}>
                        {userOrganizations.map((org, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <Card variant="outlined">
                                    <CardActionArea onClick={() => handleOrganizationSelect(org.organization)} sx={{ padding: 3 }} disableRipple>
                                        <Typography variant="h6" component="div">
                                            {org?.organization?.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {org?.organization?.website}
                                        </Typography>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
        </>
    );
};

export default ClientOrganizationList;
