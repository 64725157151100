// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Grid, IconButton, Container } from '@mui/material';
import { LocationOn, Web, Business } from '@mui/icons-material';

import ClientOrganizationList from '../ClientOrganizationList/ClientOrganizationList';

const ClientDashboard = () => {


    const activeOrg = useSelector(
        (state: RootStateOrAny) => state.global.global.activeOrganization,
    );

    useEffect(() => {

    }, []);

    const FieldWithIcon = ({ icon, text, link }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton color="primary" size="large">
                {icon}
            </IconButton>
            {link ? (
                <Typography variant="body1" component="a" href={link} target="_blank" rel="noopener noreferrer">
                    {text || 'N/A'}
                </Typography>
            ) : (
                <Typography variant="body1">
                    {text || 'N/A'}
                </Typography>
            )}
        </Box>
    );

    return (
        <Container maxWidth="lg">
            {activeOrg && (
                <Box sx={{ flexGrow: 1, p: 3 }}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h4" component="h2" gutterBottom>
                                {activeOrg?.title || 'Organization Name'}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FieldWithIcon
                                        icon={<Web />}
                                        text={activeOrg?.url}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FieldWithIcon
                                        icon={<Business />}
                                        text={activeOrg?.website}
                                        link={activeOrg?.website}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FieldWithIcon
                                        icon={<LocationOn />}
                                        text={(activeOrg?.address || '') + (activeOrg?.city || '')}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            )}
            {!activeOrg && <ClientOrganizationList />}
        </Container>
    );
};

export default ClientDashboard;
