import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar/AppBar";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { collapse, expand } from "./Actions";
import { collapse_sider, expand_sider } from "../Sider/Actions";
import { switchTheme } from "../../../Global/Actions";
import "../../../index.scss";
import {
    Box,
    Button,
    FormControlLabel,
    Switch,
    Tooltip,
    Chip,
    Avatar,
    Menu,
    MenuItem,
    Divider,
    Slide,
    useScrollTrigger,
    Modal,
    Paper,
    Typography,
    Container,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
    getUserData,
    getUserId,
    getUserRole,
    isLoggedIn,
} from "../../../Services/LocalStorageService";
import {
    retrieveUserOrganizations,
    retrieveActiveOrganization,
    storeActiveOrganization,
} from "../../../Services/LocalStorageService";
import { setActiveOrganizations } from "../../../Global/Actions";
import { isAuthorized } from "../../../Routes/Guards/AuthGuard/AuthGuard";
import ClientOrganizationList from "../../Screens/Dashboard/ClientOrganizationList/ClientOrganizationList";
import { getById } from "../../../Services/GenericApiService";

const logoMain = require("../../../Assets/images/main-logo.png");

const drawerWidth = 280;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: React.ReactElement;
}

function HideOnScroll(props: any) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    // ...(open && {
    //     marginLeft: drawerWidth,
    //     width: `calc(100% - ${drawerWidth}px)`,
    //     transition: theme.transitions.create(['width', 'margin'], {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.enteringScreen,
    //     }),
    // }),
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
        margin: 1,
        padding: 0,
        transform: "translateX(6px)",
        "&.Mui-checked": {
            color: "#fff",
            transform: "translateX(22px)",
            "& .MuiSwitch-thumb:before": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    "#fff"
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor:
                    theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#fff",
        width: 32,
        height: 32,
        "&:before": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                "#003892"
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    "& .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        borderRadius: 20 / 2,
    },
}));

function Header({ fixed = false, hideHeaderControls = false }) {
    const navigate = useNavigate();

    const location = useLocation();

    // Redux
    const header = useSelector((state: RootStateOrAny) => state.header);

    const sider = useSelector((state: RootStateOrAny) => state.sider);

    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme
    );

    const activeOrg = useSelector(
        (state: RootStateOrAny) => state.global.global.activeOrganization
    );

    // Dispatcher
    const dispatch = useDispatch();

    // UI vars
    const [open, setOpen] = React.useState(header.state.isExpanded);
    const [activeTheme, setActiveTheme] = React.useState(theme);
    const [userOrganizations, setUserOragnizations] = React.useState<any>([]);
    const [activeOrganization, setActiveOrganization] = useState<any>({});
    const [currentUser, setCurrentUser] = useState<any>({});
    const [userRole, setUserRole] = useState<any>({});
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElUserOrg, setAnchorElUserOrg] =
        React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const isUserOrgMenuOpen = Boolean(anchorElUserOrg);
    const menuId = "primary-search-account-menu";
    const userOrgId = "primary-search-account-menu-user-org";

    const [orgListOpen, setOrgListOpen] = useState(false);
    const [isProfileCompleted, setIsProfileCompleted] = useState(false);

    const handleOrgListOpen = () => setOrgListOpen(true);
    const handleOrgListClose = () => setOrgListOpen(false);

    // Hooks

    useEffect(() => {
        const id = getUserId();
        if (id) {
            getById("user", id).then((response) => {
                setIsProfileCompleted(
                    response?.userUserPersonalInformation?.isProfileCompleted
                );
            });
        }
    }, []);

    useEffect(() => {
        const roleid = getUserRole();
        setUserRole(roleid);
    }, []);

    useEffect(() => {
        const userData = getUserData();
        setCurrentUser(userData);
    }, []);

    useEffect(() => {
        if (activeOrg && Object.values(activeOrg).length > 0) {
            // setActiveOrganization(activeOrg)
            dispatch(setActiveOrganizations(activeOrg));
            return;
        }
    }, [activeOrg]);

    useEffect(() => {
        setOpen(!header.state.isExpanded);
    }, [header]);

    useEffect(() => {
        setOpen(sider.state.isExpanded);
    }, [sider]);

    useEffect(() => {
        dispatch(switchTheme(activeTheme));
    }, [activeTheme]);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem
                className="mb-2"
                disableRipple
                sx={{ borderBottom: "0.3px solid grey" }}
            >
                <div>
                    <div className="d-flex m-3 align-items-center">
                        <div>
                            <span className="d-flex align-items-center">
                                <Typography
                                    className="me-2"
                                    variant="overline"
                                    sx={{ fontWeight: "bold" }}
                                >
                                    {currentUser?.firstName ||
                                        "" + " " + currentUser?.lastName ||
                                        ""}
                                </Typography>
                                <Chip
                                    sx={{ p: 0 }}
                                    size="small"
                                    label={currentUser?.roleTitle}
                                />
                            </span>
                            <Typography
                                sx={{ fontSize: "0.8em" }}
                                variant="subtitle1"
                            >
                                {currentUser?.email || ""}
                            </Typography>
                        </div>
                    </div>
                </div>
            </MenuItem>
            {(isProfileCompleted || userRole===1) && (
                <Link
                    to={`/myprofile`}
                    className="full-wiidth"
                    style={{ textDecoration: "none", color: "inherit" }}
                >
                    <MenuItem>
                        <div className="mx-2">
                            <FontAwesomeIcon icon="user" />
                            <span className="ps-3">My Profile</span>
                        </div>
                    </MenuItem>
                </Link>
            )}
            <Link
                to={`/logout`}
                className="full-wiidth"
                style={{ textDecoration: "none", color: "inherit" }}
            >
                <MenuItem>
                    <div className="mx-2">
                        <FontAwesomeIcon icon="right-from-bracket" />
                        <span className="ps-3">Logout</span>
                    </div>
                </MenuItem>
            </Link>
        </Menu>
    );

    // UI toggles
    const handleDrawerOpen = () => {
        dispatch(expand_sider()); //sider expand state change
    };

    const handleDrawerClose = () => {
        dispatch(collapse_sider()); //sider expand state change
    };

    const handleDrawerToggle = () => {
        if (open) handleDrawerClose();
        else handleDrawerOpen();
    };

    const toggleTheme = () => {
        setActiveTheme(activeTheme === "light" ? "dark" : "light");
    };

    return (
        <React.Fragment>
            <HideOnScroll>
                <AppBar
                    position="fixed"
                    open={open}
                    className="bg-custom-gradient"
                >
                    <Toolbar>
                        <Typography
                            variant="h6"
                            sx={{ fontSize: "1em", fontWeight: "bold" }}
                            noWrap
                            component="div"
                        >
                            <Link to={"/dashboard"}>
                                <div className="">
                                    <img
                                        className="img-fluid mx-auto my-auto"
                                        style={{ height: "40px" }}
                                        src={logoMain}
                                    />
                                </div>
                            </Link>
                            {/* {process.env.REACT_APP_PROJECT_NAME} */}
                        </Typography>

                        {/* Logged in user Header Menu */}
                        {isLoggedIn() && !hideHeaderControls && currentUser && (
                            <Box
                                sx={{
                                    marginLeft: "auto",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <div className="ms-auto d-flex align-items-center">
                                    {isAuthorized(
                                        getUserRole(),
                                        "headerAdminControl",
                                        "features"
                                    ) && (
                                        <>
                                            <div className="me-3">
                                                <Link
                                                    to={`/dashboard`}
                                                    className=""
                                                >
                                                    <Button
                                                        variant="contained"
                                                        startIcon={
                                                            <FontAwesomeIcon
                                                                width={"0.8em"}
                                                                className="ps-2"
                                                                icon="home"
                                                            />
                                                        }
                                                    >
                                                        Home
                                                    </Button>
                                                </Link>
                                            </div>

                                            {activeOrg && (
                                                <div className="me-3">
                                                    <Link
                                                        to={`/school`}
                                                        className=""
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            startIcon={
                                                                <FontAwesomeIcon
                                                                    width={
                                                                        "0.8em"
                                                                    }
                                                                    className="ps-2"
                                                                    icon="school"
                                                                />
                                                            }
                                                        >
                                                            School
                                                        </Button>
                                                    </Link>
                                                </div>
                                            )}

                                            {/* Switch Organization Control */}
                                            {(currentUser?.accountType ===
                                                "organization" ||
                                                currentUser?.accountType ===
                                                    "admin" ||
                                                currentUser?.accountType ===
                                                    "employee") && (
                                                <div className="me-4">
                                                    <Button
                                                        variant="contained"
                                                        onClick={
                                                            handleOrgListOpen
                                                        }
                                                        startIcon={
                                                            <FontAwesomeIcon icon="building" />
                                                        }
                                                    >
                                                        Switch Organization
                                                    </Button>

                                                    <Modal
                                                        open={orgListOpen}
                                                        onClose={
                                                            handleOrgListClose
                                                        }
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                height: "100%",
                                                                padding: "16px",
                                                            }}
                                                        >
                                                            <Paper
                                                                sx={{
                                                                    position:
                                                                        "relative", // Ensure the Paper is positioned relatively
                                                                    maxHeight:
                                                                        "90vh",
                                                                    overflowY:
                                                                        "auto",
                                                                    width: "100%",
                                                                    maxWidth:
                                                                        "80vw",
                                                                    padding:
                                                                        "24px",
                                                                }}
                                                            >
                                                                <IconButton
                                                                    aria-label="close"
                                                                    onClick={
                                                                        handleOrgListClose
                                                                    }
                                                                    sx={{
                                                                        position:
                                                                            "absolute",
                                                                        right: 8,
                                                                        top: 8,
                                                                        color: (
                                                                            theme
                                                                        ) =>
                                                                            theme
                                                                                .palette
                                                                                .grey[500],
                                                                    }}
                                                                >
                                                                    <CloseIcon />
                                                                </IconButton>
                                                                <ClientOrganizationList />
                                                            </Paper>
                                                        </Box>
                                                    </Modal>
                                                </div>
                                            )}
                                        </>
                                    )}

                                    {currentUser && (
                                        <>
                                            <div className="me-2">
                                                <Tooltip title="Notifications">
                                                    <IconButton
                                                        size="large"
                                                        edge="end"
                                                        aria-label="account of current user"
                                                        // aria-controls={menuId}
                                                        aria-haspopup="true"
                                                        // onClick={handleProfileMenuOpen}
                                                        color="inherit"
                                                    >
                                                        <FontAwesomeIcon
                                                            width="0.6em"
                                                            icon="bell"
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>

                                            <div className="me-4">
                                                <IconButton
                                                    size="large"
                                                    edge="end"
                                                    aria-label="account of current user"
                                                    aria-controls={menuId}
                                                    aria-haspopup="true"
                                                    onClick={
                                                        handleProfileMenuOpen
                                                    }
                                                    color="inherit"
                                                >
                                                    {currentUser?.firstName && (
                                                        <Avatar
                                                            sx={{
                                                                bgcolor:
                                                                    "orange",
                                                                width: 35,
                                                                height: 35,
                                                            }}
                                                        >
                                                            {currentUser
                                                                ?.firstName[0] ||
                                                                currentUser
                                                                    ?.lastName[0]}
                                                        </Avatar>
                                                    )}
                                                </IconButton>
                                            </div>
                                        </>
                                    )}
                                </div>
                                {renderMenu}
                            </Box>
                        )}

                        {/* Not Logged in User */}
                        {!isLoggedIn() && !hideHeaderControls && (
                            <Box
                                sx={{
                                    marginLeft: "auto",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <div className="ms-auto d-flex align-items-center">
                                    <div className="me-4">
                                        <Link
                                            to={`/login?redirect=${encodeURIComponent(
                                                location.pathname +
                                                    location.search
                                            )}`}
                                            className=""
                                        >
                                            <div className="btn btn-success">
                                                <span>Login</span>
                                                <FontAwesomeIcon
                                                    width={"1em"}
                                                    className="ps-2"
                                                    icon="sign-in"
                                                />
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="me-4">
                                        <Link
                                            to={`/register?redirect=${encodeURIComponent(
                                                location.pathname +
                                                    location.search
                                            )}`}
                                            className=""
                                        >
                                            <div className="btn btn-success">
                                                <span>Register</span>
                                                <FontAwesomeIcon
                                                    width={"1em"}
                                                    className="ps-2"
                                                    icon="user-plus"
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </Box>
                        )}
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
        </React.Fragment>
    );
}

export default Header;
