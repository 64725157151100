import { SELECT } from "./Types";

const initialState = {
    state: {
        id: null,
        name: null,
    }
}

const CurrentSchoolReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SELECT: return {
            ...state,
            state: {
                id: action.id,
                name: action.name,
            }
        }
        default: return state
    }
}

export default CurrentSchoolReducer;
