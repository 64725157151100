import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Alert,
    CircularProgress,
    Typography,
    Card,
    CardContent,
} from "@mui/material";
import { getUserId } from "../../../../Services/LocalStorageService";
import { getById } from "../../../../Services/GenericApiService";

const CandidateDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [isProfileCompleted, setIsProfileCompleted] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const id = getUserId();
        if (id) {
            setLoading(true);
            getById("user", id)
                .then((response) => {
                    setIsProfileCompleted(
                        response?.userUserPersonalInformation
                            ?.isProfileCompleted
                    );
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching profile information:", error);
                    setLoading(false);
                });
        }
    }, []);

    const handleNavigate = (route: any) => {
        navigate(route);
    };

    if (loading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                flexGrow: 1,
                p: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 4,
            }}
        >
            {isProfileCompleted ? (
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h5" component="div" gutterBottom>
                            Welcome to 10K Schools!
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            Your profile is complete! Explore your profile and
                            vacancies available to you.
                        </Typography>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleNavigate("/myprofile")}
                        >
                            Go to My Profile
                        </Button>
                    </CardContent>
                </Card>
            ) : (
                <>
                    <Box
                        sx={{
                            maxWidth: "600px",
                            width: "100%",
                            mb: 3,
                        }}
                    >
                        <video
                            controls
                            style={{
                                width: "100%",
                                height: "auto",
                            }}
                        >
                            <source src={`${process.env.PUBLIC_URL}/assets/videos/profilecompletion.mp4`} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                    {/* <Alert severity="warning" sx={{ maxWidth: 400 }}>
                        <Typography variant="h6">Profile Completion</Typography>
                        Your profile is not complete! You need to complete your
                        profile to be able to explore vacancies. */}
                        <Box mt={2}>
                            <Button
                                variant="outlined"
                                onClick={() => handleNavigate("/onboard")}
                            >
                                Complete Profile
                            </Button>
                        </Box>
                    {/* </Alert> */}
                </>
            )}
        </Box>
    );
};

export default CandidateDashboard;
