import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    register: {
                        tagline: 'Register with email and create your password',
                        haveAccount: 'Already have an account?',
                        createSuccess: 'Account Created',
                        creationMailSent: 'An email with activation link has been sent to your inbox'
                    },
                    login: {
                        tagline: 'Enter your email and password to login',
                        dontHaveAccount: 'Don\'t have an account?',
                        loginFailed: 'Login Failed',
                        invalidCredentials: 'Invalid login credentials'
                    },
                    reset: {
                        tagline: "Reset your password here",
                        dontChange: "Don\'t want to change password?",
                        success: "Password Changed Successfully",
                        successLine: "Password of your acccount has been changed"
                    },
                    form: {
                        firstName: 'First Name',
                        lastName: 'Last Name',
                        email: 'Email',
                        workEmail: 'Work Email',
                        contact: 'Mobile Number',
                        countryCode: 'Code',
                        password: 'Password',
                        confirmPassword: 'Confirm Password'
                    },
                    common: {
                        add: 'Add',
                        create: 'Create',
                        edit: 'Update',
                        delete: 'Delete',
                        cancel: 'Cancel',
                        view: 'View',
                        applyFilter: 'Apply Filter',
                        success: 'Success',
                        error: 'Error',
                        allRecords: 'All Records',
                        recordDetails: 'Record Details',
                        tryAgain: 'Please try again later',
                        somethingWentWrong: 'Something went wrong',
                        createSuccess: 'Record created successfully',
                        editSuccess: 'Record updated successfully',
                        deleteSuccess: 'Record deleted successfully',
                        genericSuccess: 'Operation completed successfully',
                        registerAsCandidate: 'Register as Candidate',
                        unauthorized: 'You do not have permission to perfrom this operation'
                    },
                    module: {
                        register: 'Register',
                        login: 'Login',
                        reset: 'Reset',
                        role: 'Roles',
                        user: 'User',
                        subject: 'Subjects',
                        headcategory: 'Head Categories',
                        headsubcategory: 'Head Sub Categories',
                        addSubject: 'Subject',
                        addSubjectCateogry: 'Department',
                        curriculum: 'Curriculum',
                        curriculumphase: 'Curriculum Phase',
                        qualificationaward: 'Qualification Awards',
                        qualificationinstitute: 'Qualification Institutes',
                        qualificationlevel: 'Qualification Levels',
                        title: 'Title',
                        rollcount: 'Roll Counts',
                        namechangereason: 'Name Change Reason',
                        additionalresponsibility: 'Additional Responsibilities',
                        scopeofrole: 'Scope Of Roles',
                        jobfamily: 'Job Families',
                        employerfocus: 'Employer Focus',
                        employertype: 'Employer Types',
                        contractedrole: 'Contracted Roles',
                        country: 'Countries',
                        emailTemplate: 'Email Template',
                        organization: 'Organization',
                        centralRegister: 'Central Register',
                        school: 'School',
                        // add new module here %TEMPLATE_MODULE_NAME%
                    },
                    role: {
                        title: 'Title',
                    },
                    title: {
                        name: 'Title',
                    },
                    user: {
                        firstName: 'First Name',
                        middleName: 'Middle Name',
                        lastName: 'Last Name',
                        email: 'Email',
                        contact: 'Contact',
                        roleId: 'Role',
                        isActive: 'Active',
                        isMicrosoftLinked: 'nan',
                        isGoogleLinked: 'nan',
                        password: 'Password',
                        token: 'nan',
                        lastLogin: 'Last Login',
                    },
                    subject: {
                        name: 'Subject',
                    },
                    subjectCategory: {
                        name: 'Department',
                        subjectCategoryId: 'Department',
                    },
                    headcategory: {
                        name: 'Head Of',
                    },
                    headsubcategory: {
                        name: 'Head Sub Category',
                        headCategoryId: 'Head Category',
                    },
                    curriculum: {
                        name: 'Curriculum',
                    },
                    curriculumphase: {
                        name: 'Phase',
                        curriculumId: 'Curriculum',
                    },
                    qualificationaward: {
                        name: 'Awards',
                    },
                    qualificationinstitute: {
                        name: 'Institute',
                    },
                    qualificationlevel: {
                        name: 'Level',
                        instituteId: 'Institute',
                        awardId: 'Award',
                        major: 'Major',
                        isMajor: 'Allow Major',
                        isAward: 'Allow Award',
                    },
                    rollcount: {
                        name: 'Number Of Roll',
                    },
                    namechangereason: {
                        name: 'Name Change Reasons',
                    },
                    additionalresponsibility: {
                        name: 'Additional Responsibility',
                    },
                    scopeofrole: {
                        name: 'Scope Of Role',
                    },
                    jobfamily: {
                        name: 'Job Family',
                        isAcademic: 'Is Academic',
                    },
                    employerfocus: {
                        name: 'Employer Focus',
                    },
                    employertype: {
                        name: 'Employer Type',
                        employerFocusId: 'Employer Focus',
                    },
                    country: {
                        name: 'Name',
                        flag: 'Flag',
                    },
                    profile: {
                        employerName: 'Employer Name',
                        employerWebsite: 'Employer Website',
                        employerFocus: 'Employer Focus',
                        jobTitle: 'Job Title',
                    },
                    contractedrole: {
                        name: 'Role',
                        isScopeOfRole: 'Allow Scope Of Role',
                        isResponsibilities: 'Allow Responsibilities',
                        isRollCount: 'Allow Roll Count',
                        isSubjectCategory: 'Allow Subject Category',
                        isSubjectSubCategory: 'Allow Subject Sub Category',
                        isAdditionalResponsibilities: 'Allow Additional Responsibilities',
                    },
                    organization: {
                        title: 'Title',
                        website: 'Website',
                        address: 'Address',
                        createCluster: 'Add Cluster',
                        createRegion: 'Add Region',
                        createSchool: 'Add School',
                        removeCluster: 'Remove Cluster',
                        removeRegion: 'Remove Region',
                        level: 'Heirarchy Level',
                        isClusterLevel: 'Cluster Level (L2)',
                        isRegionalLevel: 'Regional Level (L3)',
                        isTopLevel: 'Top Level (L1)',
                        isSchoolLevel: 'School Level'
                    },
                    vacancy: {
                        successCandidateMove: 'Candidate Moved Successfully',
                        errorCandidateMove: 'Candidate could not be moved',
                    },
                    cluster: {
                        name: 'Cluster Name',
                        create: 'Create Cluster'
                    },
                    region: {
                        name: 'Region Name',
                        create: 'Create Region'
                    },
                    school: {
                        name: 'School Name',
                        create: 'Create School',
                        ukECT: 'UK ECT considered',
                        ukQTS: 'UK QTS required',
                    },
                    reference: {
                        isThisHrEmail: 'This is an HR email',
                        objectionText: 'Do you have any objection to us contacting this referee now?',
                    },
                    policeCheck: {
                        title: 'Police Clearance & Safeguarding Declarations',
                        titleText: 'Higher profile schools will give interview preference to those with upto date police clearances.',
                        convictedByCourt: 'Have you ever been convicted by any court in any jurisdiction of any criminal offence?',
                        receivedWarningFromPolice: 'Have you ever received a caution, reprimand, or final warning from the police in any country?',
                        currentlySubjectToCriminalInvestigation: 'Are you currently subject to any criminal investigation in any country?',
                        everBeenSubjectToCriminalInvestigation: 'Have you ever been sibject to any investigation in any country?',
                        investigationRelatedToChild: 'Have you ever been subject to any investogation in relation to child protection matters',
                        inRelationshipWithSomeoneWithCoerciveControl: 'Are you currently living with or in relationship with anyone who exerts coercive control over you?',
                        obtainPoliceCheckText: 'Would you like us to obtain Police clearance?',
                        criminalCheckText: 'Police Clearance / Criminal Record checks',
                        criminalCheckSubText: 'This data has been compiled from the career/education history that you have provided. If the residency is incorrect below please amend your education/ career information for that time period.',
                        ukCost: 'UK Cost',
                        uaeCost: 'AE Cost',
                        ukCostPrice: '$68.50',
                        uaeCostPrice: '$72.80',
                        noChargeInfoText: `Whilst there's no requirements to obtain any missing documents through 10K School, we offer this service with no surcharge, eg. you only pay exactly what it costs us to obtain the clearance.`,

                    }

                }
            },
            de: {
                translation: {
                    description: {
                        part1: 'Ändere <1>src/App.js</1> und speichere um neu zu laden.',
                        part2: 'Lerne React'
                    }
                }
            }
        }
    });

export default i18n;
