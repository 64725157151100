import jwt_decode from "jwt-decode";

const jwt_data_key: string = process.env.REACT_APP_JWT_DATA_KEY || 'foundation_app_user_data';
const user_organization: string = 'user_organizations';
const activeOrganizationKey: string = 'active_organization'

export function storeJWT(_token: string) {
    removeJWT();    // remove previous jwt (if any)
    removeUserOrganizations();
    removeActiveOrganization();
    localStorage.setItem(jwt_data_key, JSON.stringify({
        jwt: _token
    }));
}

// Retrieve token from local storage
export function retrieveJWT() {
    const jwt = localStorage.getItem(jwt_data_key);
    if (jwt) {
        return JSON.parse(jwt).jwt;
    } else {
        return null;
    }
}

// Function to store the active organization in localStorage
export function storeActiveOrganization(activeOrganization: any) {
    removeActiveOrganization();
    localStorage.setItem(activeOrganizationKey, JSON.stringify(activeOrganization));
}

// Function to retrieve the active organization from localStorage
export function retrieveActiveOrganization() {
    const activeOrganizationData = localStorage.getItem(activeOrganizationKey);
    if (activeOrganizationData) {
        return JSON.parse(activeOrganizationData);
    } else {
        return null; // or a default value if preferred
    }
}
export function storeUserOrganizations(organizationsList: any) {
    removeUserOrganizations();
    localStorage.setItem(user_organization, JSON.stringify(organizationsList));
}

// Retrieve token from local storage
export function retrieveUserOrganizations() {
    const organizationsList = localStorage.getItem(user_organization);
    if (organizationsList) {
        return JSON.parse(organizationsList);
    } else {
        return null;
    }
}

export function removeUserOrganizations() {
    localStorage.removeItem(user_organization);
}

export function removeActiveOrganization() {
    localStorage.removeItem(activeOrganizationKey);
}

export function removeJWT() {
    localStorage.removeItem(jwt_data_key);
}

export function clearLocalStorage() {
    removeJWT();
    removeActiveOrganization();
    removeUserOrganizations();
}

export function decryptJWT(_jwt: string) {
    return jwt_decode(_jwt);
}

export function isJWTExpired(_jwt: string) {
    let decodedToken: any = decryptJWT(_jwt);
    let currentDate = new Date();
    // JWT exp is in seconds
    return decodedToken.exp * 1000 < currentDate.getTime();
}

export function getUserData() {
    const jwt = retrieveJWT();
    if (!jwt) {
        return null;
    } else {
        return decryptJWT(jwt);
    }
}

export function getUserId() {
    const jwt = retrieveJWT();
    if (!jwt) {
        return null;
    } else {
        const user: any = decryptJWT(jwt);
        return user.id;
    }
}

export function getUserRole() {
    const jwt = retrieveJWT();
    if (!jwt) {
        return null;
    } else {
        const user: any = decryptJWT(jwt);
        return user.roleId;
    }
}

export function isLoggedIn() {
    const jwt = retrieveJWT();
    if (!jwt) {
        return false;
    } else {
        const validJwt = isJWTExpired(jwt);
        return !validJwt;
    }
}

export function isAdmin() {
    const jwt = retrieveJWT();
    if (!jwt) {
        return false;
    } else {
        const user: any = decryptJWT(jwt);
        return user.roleId == process.env.REACT_APP_ADMIN_ROLE_ID
    }
}
