import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getById } from '../../Services/GenericApiService';
import { setActiveOrganizations } from '../../Global/Actions';

function OrganizationFetcher() {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const hostname = window.location.hostname;
        const baseDomain = process.env.REACT_APP_CLIENT_HOST;
        const parts = hostname.split('.');
        const subDomainLenght: any = process.env.REACT_APP_SUBDOMAIN_LENGTH

        if (parts.length > parseInt(subDomainLenght) && hostname !== baseDomain) {
            const subdomain = parts[0];
            getById('organization/url', subdomain)
                .then(orgDetails => {
                    dispatch(setActiveOrganizations(orgDetails));
                })
                .catch(error => {
                    console.error('Error fetching organization details:', error);
                });
        }
    }, [location, dispatch]);

    return null; // This component doesn't render anything
}

export default OrganizationFetcher;