import React, { useEffect, useState } from 'react';
import { useStyles } from './Styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import CandidateDashboard from '../CandidateDashboard/CandidateDashboard';
import ClientDashboard from '../ClientDashboard/ClientDashboard';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Header from "../../../Layout/Header/Header";
import Sider from "../../../Layout/Sider/Sider";
import { getUserRole, isAdmin } from '../../../../Services/LocalStorageService';
import { isLoggedInUserCandidate, isLoggedInUserClient } from '../../../../Routes/Guards/AuthGuard/AuthGuard';


function Home() {
    const classes = useStyles();

    // const [dashboardVisisble, setDashboardVisisble] = useState(false);

    useEffect(() => {
        // isAdmin() ? setDashboardVisisble(true) : setDashboardVisisble(false)
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <Header />

            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>

                {isLoggedInUserCandidate() && <CandidateDashboard />}

                {(isAdmin() || isLoggedInUserClient()) && <ClientDashboard />}

            </Box>

        </Box>
    );
}

export default Home;
