import { retrieveJWT, removeJWT } from "./LocalStorageService";

const axios = require("axios");

// axios.interceptors.response.use(
//     (response: any) => {
//         // Handle successful response
//         return response;
//     },
//     (error: any) => {
//         // Handle error response
//         const status = error.response ? error.response.status : null;
//         if (status === 401) {
//             // Assuming removeJWT is a function that clears the JWT from storage
//             removeJWT();
//             // Redirect to login page, ensuring to save the current location for redirect after login
//             const loginPath = `/login`;
//             window.location.href = loginPath;
//             return Promise.reject(error);
//         }
//         return Promise.reject(error);
//     }
// );

const generateHeaders = (headers: any = {}) => {
  const JWT = retrieveJWT();
  return {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${JWT}`,
      ...headers,
    },
  };
};

export async function post(_module: string, _data: any, headers: any = {}) {
  const url = process.env.REACT_APP_REST_ENDPOINT;
  try {
    const response = await axios.post(
      url + _module,
      _data,
      generateHeaders(headers)
    );
    return response.data;
  } catch (_err: any) {
    if (_err.response && _err.response.data) {
      // You can throw an error or handle it as needed
      throw Error(_err.response.data.message || "An error occurred");
    } else {
      // Handle the case where there is no response data in the error
      throw _err; // Or handle it as needed
    }
  }
}

export async function getAll(_module: string, page = 0, size = 0) {
  const url = process.env.REACT_APP_REST_ENDPOINT;
  let response;
  // check if with or without pagination
  if (size !== 0) {
    response = await axios.get(
      url + _module + "?page=" + page + "&size=" + size,
      generateHeaders()
    );
  } else {
    response = await axios.get(url + _module, generateHeaders());
  }
  return response.data;
}

export async function getPdf(_pdfUrl: string) {
  const downloadPdf = await axios.get(
    _pdfUrl,
    {
      responseType: "blob",
    },
    {
      headers: {
        "Content-Type": "application/pdf",
      },
    }
  );
  return downloadPdf;
}

export async function getAllByCustomParams(_module: string, params: string) {
  const url = process.env.REACT_APP_REST_ENDPOINT;
  let response;
  response = await axios.get(url + _module + "?" + params, generateHeaders());
  return response.data;
}

export async function getById(_module: string, _id: string) {
  const url = process.env.REACT_APP_REST_ENDPOINT;
  const response = await axios.get(
    url + _module + "/" + _id,
    generateHeaders()
  );
  return response.data;
}

export async function patch(_module: string, _id: string, _data: any) {
  const url = process.env.REACT_APP_REST_ENDPOINT;
  const response = await axios.patch(
    url + _module + "/" + _id,
    _data,
    generateHeaders()
  );
  return response.data;
}

export async function put(_module: string, _id: string, _data: any) {
  const url = process.env.REACT_APP_REST_ENDPOINT;
  const response = await axios.put(
    url + _module + "/" + _id,
    _data,
    generateHeaders()
  );
  return response.data;
}

export async function bulkPatch(_module: string, _data: any) {
  const url = process.env.REACT_APP_REST_ENDPOINT;
  const response = await axios.patch(
    url + _module + "/",
    _data,
    generateHeaders()
  );
  return response.data;
}

export async function deleteById(_module: string, _id: string) {
  const url = process.env.REACT_APP_REST_ENDPOINT;
  const response = await axios.delete(
    url + _module + "/soft/" + _id,
    generateHeaders()
  );
  return response.data;
}
export async function deletePermanentById(_module: string, _id: string) {
  const url = process.env.REACT_APP_REST_ENDPOINT;
  const response = await axios.delete(
    url + _module + "/hard/" + _id,
    generateHeaders()
  );
  return response.data;
}

export async function deleteWithPayload(_module: string, data: any) {
  const url = process.env.REACT_APP_REST_ENDPOINT;
  const response = await axios.delete(url + _module, data, generateHeaders());
  return response.data;
}

export async function deleteWithId(_module: string, data: any,) {
  const url = process.env.REACT_APP_REST_ENDPOINT;
  const response = await axios.delete(url + _module + "/" + data, generateHeaders());
  return response.data;
}

export async function getFile(_module: string) {
  const url = process.env.REACT_APP_RESOURCES;
  const headers: any = { ...generateHeaders(), responseType: "blob" };
  const response = await axios.get(url + _module, headers);
  return response.data;
}
