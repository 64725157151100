//@ts-nocheck
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import zxcvbn from "zxcvbn";
import {
    Button,
    Card,
    CardContent,
    TextField,
    Snackbar,
    Alert,
    AlertTitle,
    FormControl,
    IconButton,
    InputAdornment,
    Typography,
    Slide,
    Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { post } from "../../../../Services/GenericApiService";
import { encrypt } from "../../../../Services/CryptoService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const brandImageSrc2 = require("../../../../Assets/images/login-img2.png");

const schema = yup
    .object({
        password: yup
            .string()
            .min(8, "Password must be at least 8 characters long")
            .test(
                "password-validation",
                "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character",
                (value) => {
                    if (!value) return true; // Pass validation if value is empty or undefined
                    const hasUpperCase = /[A-Z]/.test(value);
                    const hasLowerCase = /[a-z]/.test(value);
                    const hasNumber = /[0-9]/.test(value);
                    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
                    return (
                        hasUpperCase &&
                        hasLowerCase &&
                        hasNumber &&
                        hasSpecialChar
                    );
                }
            )
            .required("Password is required"),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Confirm Password is required"),
    })
    .required();

function Reset() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [showPassword, setShowPassword] = React.useState(false);
    const [passwordStrength, setPasswordStrength] = useState({
        score: 0,
        feedback: "",
    });
    const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false);
    const [openErrorAlert, setOpenErrorAlert] = React.useState(false);
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get("id");

    // Call this function every time the password field is updated
    const checkPasswordStrength = (password) => {
        const result = zxcvbn(password);
        setPasswordStrength({
            score: result.score,
            feedback:
                result.feedback.warning || result.feedback.suggestions[0] || "",
        });
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();

    const handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };

    const onSubmit = (data) => {
        setLoading(true);
        data.password = encrypt(data.password);
        let payload = {
            password: data.password,
            UserId: userId,
        };
        console.log(payload);
        post("user/auth/reset", payload)
            .then((res) => {
                setLoading(false);
                setOpenSuccessAlert(true);
                setTimeout(()=>navigate('/login'), 2000);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
                setOpenErrorAlert(true);
                setError(err.message);
            });
    };

    return (
        <section className="h-100 d-flex justify-content-center align-items-center bg-light">
            <Box sx={{ maxWidth: 400, width: "100%" }}>
                <div className="py-3 h-100 d-flex align-items-center">
                    <Slide
                        direction="left"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                    >
                        <Card
                            variant="outlined"
                            sx={{ p: 3, width: "100%", overflow: "hidden" }}
                        >
                            <CardContent>
                                <div className="col-md-12 mx-md-2 mx-0">
                                    <div className="col-md-8 mx-auto">
                                        <img
                                            className="img-fluid mx-auto my-auto"
                                            src={brandImageSrc2}
                                            alt=""
                                        />
                                    </div>
                                    <div className="mt-5">
                                        <p>{t("reset.tagline")}</p>
                                    </div>
                                </div>

                                <form
                                    className="row px-2"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <FormControl fullWidth className="my-2">
                                        <TextField
                                            {...register("password")}
                                            label={t("form.password")}
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            error={!!errors.password}
                                            helperText={
                                                errors.password?.message
                                            }
                                            onChange={(e) =>
                                                checkPasswordStrength(
                                                    e.target.value
                                                )
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={
                                                                handleClickShowPassword
                                                            }
                                                            onMouseDown={
                                                                handleMouseDownPassword
                                                            }
                                                        >
                                                            {showPassword ? (
                                                                <FontAwesomeIcon
                                                                    icon={faEye}
                                                                />
                                                            ) : (
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEyeSlash
                                                                    }
                                                                />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: "",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {passwordStrength.feedback}
                                            </Typography>
                                            {watch("password") !==
                                                undefined && (
                                                <Typography variant="subtitle2">
                                                    Password Strength:&nbsp;
                                                    {(passwordStrength.score ===
                                                        0 ||
                                                        passwordStrength.score ===
                                                            1 ||
                                                        errors.password) &&
                                                        "Weak"}
                                                    {passwordStrength.score ===
                                                        2 &&
                                                        !errors.password &&
                                                        "Fair"}
                                                    {passwordStrength.score ===
                                                        3 &&
                                                        !errors.password &&
                                                        "Good"}
                                                    {passwordStrength.score ===
                                                        4 &&
                                                        !errors.password &&
                                                        "Strong"}
                                                </Typography>
                                            )}
                                        </Box>
                                    </FormControl>

                                    <FormControl fullWidth className="my-2">
                                        <TextField
                                            {...register("confirmPassword")}
                                            label={t("form.confirmPassword")}
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            error={!!errors.confirmPassword}
                                            helperText={
                                                errors.confirmPassword?.message
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={
                                                                handleClickShowPassword
                                                            }
                                                            onMouseDown={
                                                                handleMouseDownPassword
                                                            }
                                                        >
                                                            {showPassword ? (
                                                                <FontAwesomeIcon
                                                                    icon={faEye}
                                                                />
                                                            ) : (
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEyeSlash
                                                                    }
                                                                />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>

                                    <LoadingButton
                                        loading={loading}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="full-width my-2"
                                    >
                                        {t("module.reset")}
                                    </LoadingButton>
                                </form>

                                <Typography
                                    variant="body2"
                                    className="text-center my-2"
                                >
                                    {t("reset.dontChange")}{" "}
                                    <Button
                                        color="secondary"
                                        onClick={() => navigate("/login")}
                                    >
                                        {t("module.login")}
                                    </Button>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Slide>
                </div>

                <Snackbar
                    open={openSuccessAlert}
                    autoHideDuration={6000}
                    onClose={handleCloseAlert}
                >
                    <Alert
                        onClose={handleCloseAlert}
                        severity="success"
                        sx={{ width: "100%" }}
                    >
                        <AlertTitle>{t("reset.success")}</AlertTitle>
                        {t("reset.successLine")}
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={openErrorAlert}
                    autoHideDuration={6000}
                    onClose={handleCloseAlert}
                >
                    <Alert
                        onClose={handleCloseAlert}
                        severity="error"
                        sx={{ width: "100%" }}
                    >
                        <AlertTitle>
                            {t("common.somethingWentWrong")}
                        </AlertTitle>
                        {error}
                    </Alert>
                </Snackbar>
            </Box>
        </section>
    );
}

export default Reset;
