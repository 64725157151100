import {combineReducers} from 'redux';
import AuthenticationReducer from "../Components/Screens/Authentication/Login/Reducer";
import SiderReducer from "../Components/Layout/Sider/Reducer";
import HeaderReducer from "../Components/Layout/Header/Reducer";
import GlobalReducer from "../Global/Reducer";
import CurrentSchoolReducer from '../Components/Screens/Dashboard/SchoolDashbord/SchoolDashbordMain/Reducer';

const RootReducer = combineReducers({
    authentication: AuthenticationReducer,
    sider: SiderReducer,
    header: HeaderReducer,
    global: GlobalReducer,
    currentSchool: CurrentSchoolReducer,
})

export default RootReducer
