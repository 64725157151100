import {COLLAPSE, EXPAND} from "./Types";

export const collapse_sider = () => {
    return {
        type: COLLAPSE
    }
}
export const expand_sider = () => {
    return {
        type: EXPAND
    }
}
